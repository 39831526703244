<template>
  <div class="address-modal__map">
    <ClientOnly v-if="mapReady">
      <yandex-map
        :settings="settings"
        :options="options"
        :coords="coords"
        zoom="14"
        :controls="[]"
        @click="getLocation"
        @map-was-initialized="init"
      >
        <ymap-marker :icon="icon" :coords="coords" marker-id="123" />
      </yandex-map>
    </ClientOnly>
  </div>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import ClientOnly from "vue-client-only";

export default {
  name: "MapDeliveryComponent",
  data() {
    const key = this.$store.state._env.YANDEX_MAP_API_KEY;
    return {
      search: "",
      mapReady: false,
      coords: [42.978901702100345, 47.511897874391494],
      marker: null,
      map: null,
      address: null,
      premiseNumber: null,
      settings: {
        apiKey: key,
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#image",
        imageHref: "/static/svg/marker.svg",
        imageSize: [40, 56],
        imageOffset: [-18, -56],
        balloonImageSize: [0, 0],
      },
      options: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: false,
      },
    };
  },
  created() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      window.ymaps.ready(this.init);
      this.mapReady = true;

      if (this.selectedAddress && this.mapReady) {
        let address = this.selectedAddress;
        this.$emit("setAddress", address.address, 1);
        this.getLocation(null, [address.latitude, address.longitude]);
      }

      // window.ymaps.ready(() => {
      //   window.ymaps.geocode(m.address).then((res) => {
      //     m.coords = res.geoObjects.get(0).geometry.getCoordinates();
      //   });
      // });
    });
  },
  mounted() {
    // if (this.selectedAddress && this.mapReady) {
    //   console.log(this.selectedAddress.address);
    //   let address = this.selectedAddress;
    //   this.getLocation(null, [address.latitude, address.longitude]);
    //   // this.$emit("setAddress", address, 1);
    // }
    // if (this.currentAddress) {
    //   this.search = this.currentAddress;
    // }
  },
  computed: {
    currentAddress() {
      let addresses = this.$store.state.auth.my_addresses;
      return addresses && addresses.length ? addresses[addresses.length - 1]?.address : null;
    },
    selectedAddress() {
      return this.$store.state.auth.client_delivery_info?.selected_address;
    },
  },
  methods: {
    focus() {
      this.$refs.dadata.focus();
    },
    blur() {
      this.$refs.dadata.blur();
    },
    setActive(address) {
      this.coords = [address.data.geo_lat, address.data.geo_lon];
      this.getLocation(null, this.coords, address);
    },
    init(map) {
      this.map = map;
      // поставить обработчик на событие поиска
      // if (this.map.controls) {
      //   this.map.controls
      //     .get("searchControl")
      //     .options.set({ noPlacemark: true, placeholderContent: "Введите адрес доставки" });
      // }
    },
    getLocation(e, coords, address) {
      this.coords = e ? e.get("coords") : coords;
      // Если метка уже создана – просто передвигаем ее.
      if (this.marker) {
        this.marker.geometry.setCoordinates(this.coords);
      }
      // Если нет – создаем.
      else {
        this.marker = this.createPlacemark(this.coords);
        this.map?.geoObjects.add(this.marker);
        // Слушаем событие окончания перетаскивания на метке.
        this.marker.events.add("dragend", () => {
          this.getAddress(this.marker.geometry.getCoordinates());
        });
      }
      this.getAddress(this.coords, address);
    },
    createPlacemark(coords) {
      return new window.ymaps.Placemark(
        coords,
        {
          iconLayout: "default#image",
        },
        {
          iconLayout: "default#image",
          imageHref: "/static/images/map-red-location.png",
          iconImageSize: [0, 0],
        }
      );
    },
    // Определяем адрес по координатам (обратное геокодирование).
    getAddress(coords, address) {
      if (address) {
        window.ymaps.geocode(address.value).then((res) => {
          let firstGeoObject = res.geoObjects.get(0);
          let addressCoords = firstGeoObject.geometry.getCoordinates();
          this.$emit("setCoords", addressCoords);
          this.premiseNumber = firstGeoObject.getPremiseNumber();
          // this.address = this.search = firstGeoObject.getThoroughfare() || firstGeoObject.getLocalities()[0];
          this.address = this.search = firstGeoObject.getAddressLine();
          this.coords = addressCoords;
          if (this.premiseNumber) {
            this.$emit("setAddress", this.address, this.premiseNumber);
          } else this.$emit("setAddress", address.value, this.premiseNumber);
        });
      } else {
        window.ymaps.geocode(coords).then((res) => {
          this.$emit("setCoords", coords);
          let firstGeoObject = res.geoObjects.get(0);
          this.premiseNumber = firstGeoObject.getPremiseNumber();
          // this.address = this.search = firstGeoObject.getThoroughfare() || firstGeoObject.getLocalities()[0];
          this.address = this.search = firstGeoObject.getAddressLine();
          this.$emit("setAddress", this.address, this.premiseNumber);
        });
      }
    },
  },
  components: { yandexMap, ymapMarker, ClientOnly },
};
</script>

<style lang="stylus">
.address-modal {
  &.default-modal {
    width 100%
    max-width 720px
    overflow hidden
    border-radius: 30px;
    padding 50px
    +below(680px) {
      padding 20px
    }
  }

  & &__header {
    display flex
    flex-direction column
    align-items flex-start
    gap: 15px
  }

  &__search {
    display flex
    gap: 20px
    width 100%
    +below(580px) {
      flex-direction column
    }

    &-input {
      flex-grow 1

      .input__action {
        .icon svg path {
          fill var(--dark-light)
        }
      }
    }
  }

  &__map {
    width 100%
    height 600px
    +below(440px) {
      height 400px
    }

    .ymap-container {
      width 100%
      height 100%
      border-radius: var(--big-radius)
      overflow hidden
    }
  }
}
</style>
